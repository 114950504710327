<template>
  <div>
    <van-popup
      v-model="viewShow"
      round
      :style="{ width: propWidth ? propWidth : '75%' }"
      @close="leftButtonClick"
    >
      <div class="form">
        <div style="text-align: center;margin-bottom: 25px">
          {{ type === "add" ? "添加患者" : "编辑患者" }}
        </div>
        <div class="form_card">
          <div class="form_label">
            <span style="color: red">* </span>昵称/姓名：
          </div>
          <input
            v-model="form.name"
            type="text"
            placeholder="请输入"
            class="form_input"
          />
        </div>
        <div class="form_card">
          <div class="form_label"><span style="color: red">* </span>性别：</div>
          <van-radio-group
            style="flex: 1;"
            v-model="form.sex"
            direction="horizontal"
          >
            <van-radio :name="1">男</van-radio>
            <van-radio :name="2">女</van-radio>
          </van-radio-group>
        </div>
        <div class="form_card" @click="dateBtn">
          <div class="form_label">出生日期：</div>

          <input
            type="text"
            placeholder="请选择"
            v-model="form.birthday"
            style="z-index: -9999"
            class="form_input"
          />
        </div>

        <div class="form_btn">
          <div class="button left-button" @click="leftButtonClick">取消</div>
          <div class="button right-button" @click="rightButtonClick">保存</div>
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model="dataShow"
      round
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        v-model="date"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="dateOk"
        @cancel="
          () => {
            this.dataShow = false;
          }
        "
      />
    </van-popup>
  </div>
</template>

<script>
import moment from "moment";
import { Toast } from "vant";
import { postFormulaPatientApi, putFormulaPatientApi } from "@/service/api";

export default {
  name: "medicalForm",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    memberId: {
      type: String,
      required: true,
      default: "",
    },
    type: {
      type: String,
      required: true,
      default: "add",
    },
    editData: {
      type: Object,
      required: false,
    },
    propWidth: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      viewShow: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      date: new Date(),
      form: {
        name: "",
        sex: null,
        birthday: "",
      },
      dataShow: false,
    };
  },
  watch: {
    visible() {
      this.viewShow = this.visible;
      if (this.visible) {
        this.form = {};
        if (this.type === "edit") {
          this.form = { ...this.editData };
        }
      }
    },
  },
  mounted() {
    // this.form = {...this.editData}
  },
  methods: {
    dateBtn() {
      // if (this.form.birthday && this.form.birthday!==''){
      //   this.date=JSON.parse(JSON.stringify(this.form.birthday))
      // }

      this.dataShow = true;
    },
    dateOk(value) {
      this.form.birthday = moment(value).format("YYYY-MM-DD");

      this.dataShow = false;
    },
    leftButtonClick() {
      this.$emit("close");
    },
    async rightButtonClick() {
      if (!this.form.name || this.form.name.trim().length === 0) {
        Toast("请填写昵称/姓名");
        return;
      }
      if (!this.form.sex) {
        Toast("请选择性别");
        return;
      }
      if (this.type === "add") {
        const data = {
          name: this.form.name,
          sex: this.form.sex,
          birthday: this.form.birthday,
        };
        const res = await postFormulaPatientApi(data);
        if (res.code === 0) {
          Toast.success("新增成功");
          this.$emit("ok");
          this.form = {
            name: "",
            sex: null,
            birthday: "",
          };
          this.$emit("close");
        } else {
          Toast.fail(res.message);
        }
      } else if (this.type === "edit") {
        const data = {
          ...this.form,
        };
        const res = await putFormulaPatientApi(data);
        if (res.code === 0) {
          Toast.success("修改成功");
          this.$emit("ok");
          this.form = {
            name: "",
            sex: null,
            birthday: "",
          };
          this.$emit("close");
        } else {
          Toast.fail(res.message);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  padding: 10px 15px;
  margin: 10px 0 30px 0;
  font-size: 15px;
  color: #333333;
  .form_card {
    width: 100%;
    margin: 20px 0;
    display: flex;
    font-size: 13px;
    align-items: center;
  }
  .form_input {
    width: 150px;
    border: #c8c9cc 1px solid !important;
    border-radius: 5px;
    height: 25px;
  }
  .form_input:disabled {
    color: inherit;
    background: white !important;
    border: #c8c9cc 1px solid !important;
  }
  .form_label {
    text-align: right;
    width: 80px;
  }
  .form_btn {
    display: flex;
    width: 80%;
    margin: 0 auto;

    justify-content: space-between;
    font-size: 13px;
    .button {
      margin-top: 10px;
      border-radius: 6px;
      padding: 3px 30px;
    }
    .left-button {
      background-color: #d0d0d0;
      color: #656565;
    }
    .right-button {
      background-color: #0091fb;
      color: white;
    }
  }
}
</style>
